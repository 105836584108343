import React, { lazy, useEffect } from 'react'
import './App.css';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom'
import { themeChange } from 'theme-change'
import checkAuth from './app/auth.js';
import initializeApp from './app/init.js';
import { BranchProvider } from './contexts/branchContext.js';
import { QueryClient, QueryClientProvider } from 'react-query';


// Importing pages
const Layout = lazy(() => import('./containers/Layout.js'))
const Login = lazy(() => import('./pages/Login.js'))
const ForgotPassword = lazy(() => import('./pages/ForgotPassword.js'))
const Register = lazy(() => import('./pages/Register.js'))
const Documentation = lazy(() => import('./pages/Documentation.js'))


// Initializing different libraries
initializeApp()


// Check for login and initialize axios
const token = checkAuth()

const queryClient = new QueryClient();


function App() {

  // useEffect(() => {
  //   // 👆 daisy UI themes initialization
  //   themeChange(false)
  // }, [])


  return (
    <>
    <React.StrictMode>
     <QueryClientProvider client={queryClient}>
    <BranchProvider>
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/register" element={<Register />} />
          <Route path="/documentation" element={<Documentation />} />
          
          {/* Place new routes over this */}
          <Route path="/app/*" element={<Layout />} />

          <Route path="*" element={<Navigate to={token ? "/app/dashboard" : "/login"} replace />}/>

        </Routes>
      </Router>
      </BranchProvider>
      </QueryClientProvider>
      </React.StrictMode>
    </>
  )
}

export default App
