import React, { createContext, useContext, useState } from 'react';

const BranchContext = createContext();

export const useBranch = () => useContext(BranchContext);

export const BranchProvider = ({ children }) => {
    const [selectedBranch, setSelectedBranch] = useState(localStorage.getItem('selectedBranch'));

    const changeBranch = (branchId) => {
        setSelectedBranch(branchId);
        localStorage.setItem('selectedBranch', branchId);
    };

    return (
        <BranchContext.Provider value={{ selectedBranch, changeBranch }}>
            {children}
        </BranchContext.Provider>
    );
};